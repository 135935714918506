import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  CBadge,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CButton,
  CContainer,
  CSwitch,
  CSelect,
  CCardFooter,
  CForm,
  CFormGroup,
  CLabel,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CInputCheckbox,
  CAlert,
} from "@coreui/react";
import firebase from "firebase";
import moment from "moment";
import CIcon from "@coreui/icons-react";
import { List, arrayMove } from "react-movable";
import useVodInteractiveStore from "src/stores/VodInteractive";
import { vodInteractiveType } from "src/stores/types";

const VodNewInteractivity = ({ match }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [darkMode, setDarkMode] = useState(true);
  const usersData = useSelector((state) => state.usersData);
  const [modalAlert, setModalAlert] = useState(null);
  const [footerAlert, setFooterAlert] = useState(null);

  const [interactivityDetails, setInteractivityDetails] = useState({});
  const [actionDeatails, setActionDeatails] = useState({});

  //zustand store
  const {
    interactivities,
    interactives,
    // profileInteractions,
    interactiveStatus,
    interactiveError,
    createVodInteractivity,
    getResourceInteractives,
    updateInteractivity,
    // deleteInteractivity,
    // getUserProfileInteractions,
    // resetProfileInteractions,
  } = useVodInteractiveStore();

  //useEffects
  useEffect(() => {
    if (match.params.episodeId) {
      console.log(match.params.episodeId);
      getResourceInteractives({
        resourceId: match.params.resourceId,
        episodeId: match.params.episodeId,
      });
    } else {
      getResourceInteractives({ resourceId: match.params.resourceId });
    }
  }, []);

  useEffect(() => {
    if (
      interactiveStatus === vodInteractiveType.UPDATEVODINTERACTIVITY_SUCCESS
    ) {
      setFooterAlert({
        type: "success",
        message: "Changes has been saved successfully",
      });
      setTimeout(() => {
        if (match.params.episodeId) {
          history.push(
            `/resource/${match.params.resourceId}/episode/${match.params.episodeId}/vodInteractivity`
          );
        } else {
          history.push(`/vodInteractivity/${match.params.resourceId}`);
        }
        setFooterAlert(null);
      }, 2000);
    } else if (
      interactiveStatus === vodInteractiveType.CREATEVODINTERACTIVITY_SUCCESS
    ) {
      setFooterAlert({
        type: "success",
        message: "New Interactivity created successfully",
      });
      setTimeout(() => {
        if (match.params.episodeId) {
          history.push(
            `/resource/${match.params.resourceId}/episode/${match.params.episodeId}/vodInteractivity`
          );
        } else {
          history.push(`/vodInteractivity/${match.params.resourceId}`);
        }
        setFooterAlert(null);
      }, 2000);
    }
  }, [interactiveStatus]);

  useEffect(() => {
    const length = 6;
    const newId = Math.random().toString(36).substr(2, length);

    console.log(`match.params.interactiveId`,match.params.interactiveId);
    
    // setInteractivityDetails(initDetails);
    if (match.params.resourceId && match.params.interactiveId) {
      const filteredInteractives = interactivities?.filter(
        (interactive) =>
          // interactive.actions[0].interactiveId === match.params.type
          interactive.interactiveId === match.params.interactiveId
      );
      console.log(`filteredInteractives`, filteredInteractives[0]);

      setInteractivityDetails(filteredInteractives?.[0]);
      // setInteractivityDetails(filteredInteractives[0]?.actions[0]);
    } else if (match.params.resourceId && match.params.episodeId && match.params.interactiveId) {
      const filteredInteractives = interactivities?.filter(
        (interactive) =>
          // interactive.actions[0].interactiveId === match.params.type
          interactive.interactiveId === match.params.interactiveId
      );
      console.log(`filteredInteractives`, filteredInteractives[0]);

      setInteractivityDetails(filteredInteractives?.[0]);
      // setInteractivityDetails(filteredInteractives[0]?.actions[0]);
    } else {
      console.log(`new interactivity`);
      
      setInteractivityDetails({
        id: `action-${newId}`,
        effectId: `effect1`,
        // authorId: "",

        // resourceId: `res${newId}`,

        interactiveId: `int${newId}`,

        referenceId: `ref${newId}`,
        status: "inactive",
        // onTimeline:true,
        style: "simple",
        title: "",
        type: "poll",
        createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
        updatedAt: firebase.firestore.Timestamp.fromDate(new Date()),
        start: "", //in seconds
        end: "", //in seconds
        actionType: "seek", //seek or episode
        seekTime: 34,
        episodeId: "", //skip to this episode
        options: [],
        currentQuestion: 0,
        questions: [],
        imageUrl: "",
        data: {
          src: "/lottie/lottie1/data.json",
          name: "点赞",
        },
      });
    }
  }, [interactives]);

  const saveChanges = async () => {
    let detailsToSave = interactivityDetails;
    // const uid = firebase.auth().currentUser?.uid;
    // const timestamp = firebase.firestore.FieldValue.serverTimestamp();

    console.log(`match.params.resourceId`, match.params.resourceId);
    console.log(`match.params.type`, match.params.type);

    if (match?.params?.resourceId && match?.params?.interactiveId) {
      const filteredInteractives = interactivities?.filter(
        (interactive) => interactive?.interactiveId === match.params.type
      );

      console.log(`interactivityDetails`, interactivityDetails);
      console.log(`filteredInteractives[0]`, filteredInteractives[0]);
      // params.id = channel ID && params.type = interactivity ID
      let interactiveItem = await filteredInteractives?.find(
        (interactive) => interactive?.interactiveId === match.params.type
      );
      console.log(`interactiveItem`, JSON.stringify(interactiveItem));
      if (filteredInteractives) {
        updateInteractive(
          match.params.resourceId,
          interactiveItem,
          interactivityDetails
        );
      } else {
        console.log(`fiteredInteractivves not working`);
      }
    } else if (match.params.resourceId && match.params.episodeId) {
      createVodInteractivity(
        match.params.resourceId,
        interactivityDetails,
        match.params.episodeId
      );
    } else if (match.params.resourceId) {
      createVodInteractivity(match.params.resourceId, interactivityDetails);
    }
  };

  const updateInteractive = (resourceId, interactiveItem, updates) => {
    if (match.params.episodeId) {
      updateInteractivity({
        resourceId: resourceId,
        interactivityItem: interactiveItem,
        data: updates,
        episodeId: match.params.episodeId,
      });
    } else {
      updateInteractivity({
        resourceId: resourceId,
        interactivityItem: interactiveItem,
        data: updates,
      });
    }
  };

  const updateDetail = (updates) => {
    let newDetails = interactivityDetails;
    newDetails = { ...newDetails, ...updates };
    setInteractivityDetails(newDetails);
  };

  // const updateActionDetails = (updates) => {
  //   // Create a copy of the interactivityDetails
  //   const newDetails = { ...interactivityDetails };

  //   // Create a copy of the actions array within the newDetails
  //   const newActions = [...newDetails.actions];

  //   // Create a copy of the action you want to update
  //   const updatedAction = { ...newActions[0] };

  //   // Apply the updates to the action
  //   Object.assign(updatedAction, updates);

  //   // Update the action in the newActions array
  //   newActions[0] = updatedAction;

  //   // Update the newDetails with the updated actions
  //   newDetails.actions = newActions;

  //   setActionDeatails(newActions);

  //   // Set the interactivityDetails with the updated details
  //   setInteractivityDetails(newDetails);
  // };

  // OPTIONS
  const addOption = () => {
    let newOptions = interactivityDetails?.options;
    const length = 6;
    const newId = Math.random().toString(36).substr(2, length);

    newOptions.push({
      fullImage: false,
      iconUrl: "",
      imageUrl: "",
      linkUrl: "",
      optId: newId,
      text: "",
    });
    updateDetail({ options: newOptions });
  };

  const updateOption = (index, field, value) => {
    let newOptions = interactivityDetails?.options;
    newOptions[index] = {
      ...newOptions[index],
      [`${field}`]: value,
    };
    updateDetail({ options: newOptions });
  };

  const removeOption = (index) => {
    let newOptions = interactivityDetails?.options;
    newOptions.splice(index, 1);
    updateDetail({ options: newOptions });
  };

  // QUESTIONS
  const addQuestion = () => {
    let newQuestions = interactivityDetails?.questions;
    const length = 6;
    const newId = Math.random().toString(36).substr(2, length);
    newQuestions.push({
      choicesAnswer: 1, //index of correct answer
      imageUrl: "",
      question: "",
      questionId: newId,
      choices: [],
    });
    updateDetail({ questions: newQuestions });
  };

  const updateQuestion = (index, field, value) => {
    let newQuestions = interactivityDetails?.questions;
    newQuestions[index] = {
      ...newQuestions[index],
      [`${field}`]: value,
    };
    updateDetail({ questions: newQuestions });
  };

  const removeQuestion = (index) => {
    let newQuestions = interactivityDetails?.questions;
    newQuestions.splice(index, 1);
    updateDetail({ questions: newQuestions });
  };

  // CHOICES
  const addChoice = (questionIndex) => {
    let newChoices =
      interactivityDetails?.questions[questionIndex]?.choices || [];
    const length = 6;
    const newId = Math.random().toString(36).substr(2, length);
    newChoices?.push({
      fullImage: false,
      iconUrl: "",
      imageUrl: "",
      linkUrl: "",
      optId: newId,
      text: "",
    });
    updateQuestion(questionIndex, "choices", newChoices);
  };

  const updateChoice = (questionIndex, index, field, value) => {
    let newChoices =
      interactivityDetails?.questions[questionIndex]?.choices || [];
    if (newChoices[index]) {
      newChoices[index] = {
        ...newChoices[index],
        [`${field}`]: value,
      };
      updateQuestion(questionIndex, "choices", newChoices);
    }
  };

  const removeChoice = (questionIndex, index) => {
    let newChoices =
      interactivityDetails?.questions[questionIndex]?.choices || [];
    newChoices.splice(index, 1);
    updateQuestion(questionIndex, "choices", newChoices);
  };

  const updateChoicesAnswer = (questionIndex, newList, oldIndex, newIndex) => {
    let newQuestion = interactivityDetails?.questions[questionIndex];
    if (newQuestion?.choicesAnswer || newQuestion?.choicesAnswer === 0) {
      let choicesAnswer = newQuestion?.choicesAnswer;

      if (choicesAnswer === oldIndex) {
        choicesAnswer = newIndex;
      } else if (choicesAnswer < oldIndex) {
        if (newIndex <= choicesAnswer) {
          choicesAnswer += 1;
        }
      } else if (choicesAnswer > oldIndex) {
        if (newIndex >= choicesAnswer) {
          choicesAnswer += 1;
        }
      }

      updateQuestion(questionIndex, "choicesAnswer", choicesAnswer);
    }
  };

  return (
    <div className={`c-app ${darkMode ? "c-dark-theme" : ""}`}>
      <CContainer fluid>
        <CRow>
          <CCol md="6" className="inter-col">
            <CCard>
              <CCardHeader
                className={
                  "d-flex flex-row justify-content-between align-items-center"
                }
              >
                <h4>{match.params.type ? "Edit " : "New "} Interactivity</h4>
                <small>{match?.params?.type}</small>
              </CCardHeader>
              <CCardBody>
                <CForm
                  action=""
                  method="post"
                  encType="multipart/form-data"
                  className="form-horizontal"
                >
                  <CRow>
                    <CCol md="4">
                      <CFormGroup>
                        <CLabel htmlFor="type">Interactivity Type</CLabel>
                        <CSelect
                          custom
                          name="type"
                          id="type"
                          value={interactivityDetails?.type}
                          onChange={(e) =>
                            updateDetail({ type: e.target.value })
                          }
                        >
                          <option value={`poll`}>Poll</option>
                          <option value={`ads`}>Ads</option>
                          <option value={`quiz`}>Quiz</option>
                        </CSelect>
                      </CFormGroup>
                    </CCol>
                    <CCol md="4">
                      <CFormGroup>
                        <CLabel htmlFor="status">Status</CLabel>
                        <CSelect
                          custom
                          name="status"
                          id="status"
                          value={interactivityDetails?.status}
                          onChange={(e) =>
                            updateDetail({ status: e.target.value })
                          }
                        >
                          <option value={`inactive`}>Inactive</option>
                          <option value={`active`}>Active</option>
                          <option value={`archive`}>Archive</option>
                        </CSelect>
                      </CFormGroup>
                    </CCol>
                    <CCol md="4">
                      <CFormGroup>
                        <CLabel htmlFor="style">Style</CLabel>
                        <CSelect
                          custom
                          name="style"
                          id="style"
                          value={interactivityDetails?.style}
                          onChange={(e) =>
                            updateDetail({ style: e.target.value })
                          }
                        >
                          <option value={`simple`}>Simple</option>
                          <option value={`extras`}>With Extras</option>
                        </CSelect>
                      </CFormGroup>
                    </CCol>
                  </CRow>
                  <CFormGroup>
                    <CLabel htmlFor="title">Title</CLabel>
                    <CInput
                      type="text"
                      autoComplete="none"
                      id="title"
                      name="title"
                      value={interactivityDetails?.title || ""}
                      onChange={(e) => updateDetail({ title: e.target.value })}
                    />
                  </CFormGroup>
                  <CFormGroup>
                    <CLabel htmlFor="imagUrl">Image URL</CLabel>
                    <CInput
                      type="imagUrl"
                      autoComplete="none"
                      id="imagUrl"
                      name="imagUrl"
                      value={interactivityDetails?.imageUrl || ""}
                      onChange={(e) =>
                        updateDetail({ imageUrl: e.target.value })
                      }
                    />
                  </CFormGroup>
                  <CRow>
                    <CCol>
                      <CLabel>Start</CLabel>
                      <CInput
                        type="text"
                        autoComplete="none"
                        id="start"
                        name="start"
                        value={interactivityDetails?.start || ""}
                        onChange={(e) =>
                          updateDetail({ start: e.target.value })
                        }
                      />
                    </CCol>
                    <CCol>
                      <CLabel>End</CLabel>
                      <CInput
                        type="text"
                        autoComplete="none"
                        id="end"
                        name="end"
                        value={interactivityDetails?.end || ""}
                        onChange={(e) => updateDetail({ end: e.target.value })}
                      />
                    </CCol>
                  </CRow>

                  <CCard color="secondary" className="mt-5 mb-0">
                    <CCardHeader className="p-3">
                      <h6 className="mb-0">
                        {interactivityDetails?.type === "quiz"
                          ? "QUESTIONS"
                          : "OPTIONS"}
                      </h6>
                    </CCardHeader>
                    <CCardBody className="p-3">
                      {interactivityDetails?.type === "quiz" ? (
                        <>
                          {interactivityDetails?.questions?.map(
                            (qItem, qIndex) => {
                              return (
                                <CCard className="mb-3">
                                  <CCardBody className="p-2">
                                    <CInputGroup className="d-flex align-items-center">
                                      <h3 className="mr-2">{qIndex + 1}</h3>
                                      <CInputGroupPrepend>
                                        <CInputGroupText>
                                          Question
                                        </CInputGroupText>
                                      </CInputGroupPrepend>
                                      <CInput
                                        type="text"
                                        id={`question-${qIndex}`}
                                        name={`question-${qIndex}`}
                                        autoComplete="none"
                                        value={qItem.question}
                                        onChange={(e) =>
                                          updateQuestion(
                                            qIndex,
                                            "question",
                                            e.target.value
                                          )
                                        }
                                      />

                                      <CButton
                                        size={"sm"}
                                        className="ml-2"
                                        color="danger"
                                        onClick={(e) => {
                                          removeQuestion(qItem);
                                        }}
                                      >
                                        <CIcon name="cil-trash" />
                                      </CButton>
                                    </CInputGroup>

                                    <CInputGroup>
                                      <CInputGroupPrepend>
                                        <CInputGroupText>
                                          Image URL
                                        </CInputGroupText>
                                      </CInputGroupPrepend>
                                      <CInput
                                        type="text"
                                        id={`imageUrl-${qIndex}`}
                                        name={`imageUrl-${qIndex}`}
                                        autoComplete="none"
                                        value={qItem.iconUrl}
                                        onChange={(e) =>
                                          updateQuestion(
                                            qIndex,
                                            "imageUrl",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </CInputGroup>

                                    <h6 className="mt-3 mb-1 font-weight-bold">
                                      Choices:
                                    </h6>
                                    <List
                                      values={qItem?.choices || []}
                                      onChange={({ oldIndex, newIndex }) => {
                                        let newList = arrayMove(
                                          qItem?.choices,
                                          oldIndex,
                                          newIndex
                                        );
                                        updateQuestion(
                                          qIndex,
                                          "choices",
                                          newList
                                        );
                                        updateChoicesAnswer(
                                          newList,
                                          oldIndex,
                                          newIndex
                                        );
                                      }}
                                      renderList={({ children, props }) => (
                                        <div {...props}>{children}</div>
                                      )}
                                      renderItem={({ value, index, props }) => {
                                        const cIndex = index;
                                        const cItem = qItem?.choices[index];
                                        return (
                                          <div {...props}>
                                            <CCard
                                              className="mb-2 p-0"
                                              color="secondary"
                                            >
                                              <CCardBody
                                                className="p-2"
                                                style={{
                                                  backgroundColor:
                                                    cIndex ===
                                                    qItem.choicesAnswer
                                                      ? "rgba(0,255,0,0.2)"
                                                      : "rgba(255,0,0,0.2)",
                                                }}
                                              >
                                                <CInputGroup className="d-flex align-items-center">
                                                  {!cItem.fullImage ? (
                                                    <>
                                                      <CInputGroupPrepend>
                                                        <CInputGroupText>
                                                          <b>TEXT</b>
                                                        </CInputGroupText>
                                                      </CInputGroupPrepend>
                                                      <CInput
                                                        type="text"
                                                        id={`text-${qIndex}-${cIndex}`}
                                                        name={`text-${qIndex}-${cIndex}`}
                                                        autoComplete="none"
                                                        value={cItem.text}
                                                        onChange={(e) =>
                                                          updateChoice(
                                                            qIndex,
                                                            cIndex,
                                                            "text",
                                                            e.target.value
                                                          )
                                                        }
                                                      />
                                                    </>
                                                  ) : (
                                                    <>
                                                      <CInputGroupPrepend>
                                                        <CInputGroupText>
                                                          Image URL
                                                        </CInputGroupText>
                                                      </CInputGroupPrepend>
                                                      <CInput
                                                        type="text"
                                                        id={`imageUrl-${qIndex}-${cIndex}`}
                                                        name={`imageUrl-${qIndex}-${cIndex}`}
                                                        autoComplete="none"
                                                        value={cItem.imageUrl}
                                                        onChange={(e) =>
                                                          updateChoice(
                                                            qIndex,
                                                            cIndex,
                                                            "imageUrl",
                                                            e.target.value
                                                          )
                                                        }
                                                      />
                                                    </>
                                                  )}
                                                  {interactivityDetails?.style !==
                                                  "simple" ? (
                                                    <CFormGroup
                                                      variant="custom-checkbox"
                                                      className="ml-2"
                                                      onChange={(e) =>
                                                        updateChoice(
                                                          qIndex,
                                                          cIndex,
                                                          "fullImage",
                                                          !cItem.fullImage
                                                        )
                                                      }
                                                    >
                                                      <CInputCheckbox
                                                        custom
                                                        id={`fullImage-checkbox-${qIndex}-${cIndex}`}
                                                        name={`fullImage-checkbox-${qIndex}-${cIndex}`}
                                                        checked={
                                                          cItem.fullImage
                                                        }
                                                      />
                                                      <CLabel
                                                        variant="custom-checkbox"
                                                        htmlFor={`fullImage-checkbox-${qIndex}-${cIndex}`}
                                                      >
                                                        Full Image
                                                      </CLabel>
                                                    </CFormGroup>
                                                  ) : null}
                                                  <CButton
                                                    disabled
                                                    className="d-flex align-items-center ml-2"
                                                    style={{
                                                      backgroundColor:
                                                        "rgba(0,0,0,0.2)",
                                                      padding: 2,
                                                    }}
                                                  >
                                                    <label
                                                      className="mb-0 mr-1 ml-2"
                                                      style={{
                                                        fontSize: 12,
                                                        color: "white",
                                                      }}
                                                    >
                                                      correct answer
                                                    </label>
                                                    <CSwitch
                                                      className=""
                                                      color="warning"
                                                      checked={
                                                        cIndex ===
                                                        qItem.choicesAnswer
                                                      }
                                                      onChange={() => {
                                                        updateQuestion(
                                                          qIndex,
                                                          "choicesAnswer",
                                                          qItem.choicesAnswer ===
                                                            cIndex
                                                            ? null
                                                            : cIndex
                                                        );
                                                      }}
                                                    />
                                                  </CButton>

                                                  <CButton
                                                    size={"sm"}
                                                    className="ml-2"
                                                    color="danger"
                                                    onClick={(e) => {
                                                      removeChoice(
                                                        qIndex,
                                                        cIndex
                                                      );
                                                    }}
                                                  >
                                                    <CIcon name="cil-trash" />
                                                  </CButton>
                                                </CInputGroup>

                                                {!cItem.fullImage &&
                                                interactivityDetails?.style !==
                                                  "simple" ? (
                                                  <>
                                                    <CInputGroup>
                                                      <CInputGroupPrepend>
                                                        <CInputGroupText>
                                                          Image URL
                                                        </CInputGroupText>
                                                      </CInputGroupPrepend>
                                                      <CInput
                                                        type="text"
                                                        id={`imageUrl-${qIndex}-${cIndex}}`}
                                                        name={`imageUrl-${qIndex}-${cIndex}`}
                                                        autoComplete="none"
                                                        value={cItem.imageUrl}
                                                        onChange={(e) =>
                                                          updateChoice(
                                                            qIndex,
                                                            cIndex,
                                                            "imageUrl",
                                                            e.target.value
                                                          )
                                                        }
                                                      />
                                                    </CInputGroup>

                                                    <CInputGroup>
                                                      <CInputGroupPrepend>
                                                        <CInputGroupText>
                                                          Icon URL
                                                        </CInputGroupText>
                                                      </CInputGroupPrepend>
                                                      <CInput
                                                        type="text"
                                                        id={`iconUrl-${qIndex}-${cIndex}`}
                                                        name={`iconUrl-${qIndex}-${cIndex}`}
                                                        autoComplete="none"
                                                        value={cItem.iconUrl}
                                                        onChange={(e) =>
                                                          updateChoice(
                                                            qIndex,
                                                            cIndex,
                                                            "iconUrl",
                                                            e.target.value
                                                          )
                                                        }
                                                      />
                                                    </CInputGroup>
                                                  </>
                                                ) : null}
                                              </CCardBody>
                                            </CCard>
                                          </div>
                                        );
                                      }}
                                    />
                                    <CButton
                                      color="light"
                                      onClick={() => addChoice(qIndex)}
                                    >
                                      + Add a Choice
                                    </CButton>
                                  </CCardBody>
                                </CCard>
                              );
                            }
                          )}
                          <CButton color="info" onClick={() => addQuestion()}>
                            + Add Question
                          </CButton>
                        </>
                      ) : (
                        <>
                          <List
                            values={interactivityDetails?.options || []}
                            onChange={({ oldIndex, newIndex }) => {
                              let newList = arrayMove(
                                interactivityDetails?.options,
                                oldIndex,
                                newIndex
                              );
                              updateDetail({ options: newList });
                              // updateChoicesAnswer(newList, oldIndex, newIndex);
                            }}
                            renderList={({ children, props }) => (
                              <div {...props}>{children}</div>
                            )}
                            renderItem={({ value, index, props }) => {
                              //interactivityDetails?.options?.map((item, index) => {
                              const item = interactivityDetails?.options[index];
                              return (
                                <div {...props}>
                                  <CCard
                                    // color="light"
                                    className="mb-2"
                                  >
                                    <CCardBody className="p-2">
                                      <CInputGroup className="d-flex align-items-center">
                                        <CBadge>{item.optId}</CBadge>
                                        {!item?.fullImage ? (
                                          <>
                                            <CInputGroupPrepend>
                                              <CInputGroupText>
                                                <b>TEXT</b>
                                              </CInputGroupText>
                                            </CInputGroupPrepend>
                                            <CInput
                                              type="text"
                                              id={`text-${index}`}
                                              name={`text-${index}`}
                                              autoComplete="none"
                                              value={item.text}
                                              onChange={(e) =>
                                                updateOption(
                                                  index,
                                                  "text",
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </>
                                        ) : (
                                          <>
                                            <CInputGroupPrepend>
                                              <CInputGroupText>
                                                Image URL
                                              </CInputGroupText>
                                            </CInputGroupPrepend>
                                            <CInput
                                              type="text"
                                              id={`imageUrl-${index}`}
                                              name={`imageUrl-${index}`}
                                              autoComplete="none"
                                              value={item.imageUrl}
                                              onChange={(e) =>
                                                updateOption(
                                                  index,
                                                  "imageUrl",
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </>
                                        )}
                                        {interactivityDetails?.style !==
                                        "simple" ? (
                                          <CFormGroup
                                            variant="custom-checkbox"
                                            className="ml-2"
                                            onChange={(e) =>
                                              updateOption(
                                                index,
                                                "fullImage",
                                                !item.fullImage
                                              )
                                            }
                                          >
                                            <CInputCheckbox
                                              custom
                                              id={`fullImage-checkbox-${index}`}
                                              name={`fullImage-checkbox-${index}`}
                                              checked={item.fullImage}
                                            />
                                            <CLabel
                                              variant="custom-checkbox"
                                              htmlFor={`fullImage-checkbox-${index}`}
                                            >
                                              Full Image
                                            </CLabel>
                                          </CFormGroup>
                                        ) : null}

                                        <CButton
                                          size={"sm"}
                                          className="ml-2"
                                          color="danger"
                                          onClick={(e) => {
                                            removeOption(index);
                                          }}
                                        >
                                          <CIcon name="cil-trash" />
                                        </CButton>
                                      </CInputGroup>

                                      {!item.fullImage &&
                                      interactivityDetails?.style !==
                                        "simple" ? (
                                        <>
                                          <CInputGroup>
                                            <CInputGroupPrepend>
                                              <CInputGroupText>
                                                Image URL
                                              </CInputGroupText>
                                            </CInputGroupPrepend>
                                            <CInput
                                              type="text"
                                              id={`imageUrl-${index}`}
                                              name={`imageUrl-${index}`}
                                              autoComplete="none"
                                              value={item.imageUrl}
                                              onChange={(e) =>
                                                updateOption(
                                                  index,
                                                  "imageUrl",
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </CInputGroup>

                                          <CInputGroup>
                                            <CInputGroupPrepend>
                                              <CInputGroupText>
                                                Icon URL
                                              </CInputGroupText>
                                            </CInputGroupPrepend>
                                            <CInput
                                              type="text"
                                              id={`iconUrl-${index}`}
                                              name={`iconUrl-${index}`}
                                              autoComplete="none"
                                              value={item.iconUrl}
                                              onChange={(e) =>
                                                updateOption(
                                                  index,
                                                  "iconUrl",
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </CInputGroup>
                                        </>
                                      ) : null}
                                      {interactivityDetails?.type === "ads" ? (
                                        <CInputGroup>
                                          <CInputGroupPrepend>
                                            <CInputGroupText>
                                              Link URL
                                            </CInputGroupText>
                                          </CInputGroupPrepend>
                                          <CInput
                                            type="text"
                                            id={`linkUrl-${index}`}
                                            name={`linkUrl-${index}`}
                                            autoComplete="none"
                                            value={item.linkUrl}
                                            onChange={(e) =>
                                              updateOption(
                                                index,
                                                "linkUrl",
                                                e.target.value
                                              )
                                            }
                                          />
                                        </CInputGroup>
                                      ) : null}
                                    </CCardBody>
                                  </CCard>
                                </div>
                              );
                            }}
                          />
                          <CButton color="info" onClick={() => addOption()}>
                            + Add Option
                          </CButton>
                        </>
                      )}
                    </CCardBody>
                  </CCard>
                </CForm>
              </CCardBody>

              <CCardFooter>
                {!footerAlert ? null : (
                  <CAlert color={footerAlert.type}>
                    {footerAlert.message}
                  </CAlert>
                )}
                <CButton
                  color="primary"
                  size="lg"
                  className={"mr-2"}
                  onClick={() => {
                    saveChanges();
                  }}
                >
                  Save Changes
                </CButton>
                <CButton
                  color="secondary"
                  size="lg"
                  onClick={() =>
                    match.params.episodeId
                      ? history.push(
                          `/resource/${match.params.resourceId}/episode/${match.params.episodeId}/vodInteractivity`
                        )
                      : history.push(
                          `/vodInteractivity/${match.params.resourceId}`
                        )
                  }
                >
                  Cancel
                </CButton>
              </CCardFooter>
            </CCard>
          </CCol>
          <CCol md="6" className="inter-col"></CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default VodNewInteractivity;
