import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  CBadge,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CButton,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CContainer,
  CSwitch,
  CSelect,
  CCardFooter,
  CDataTable,
} from "@coreui/react";
import moment from "moment";
import CIcon from "@coreui/icons-react";
import useInteractiveStore from "src/stores/Interactive";
import { interactiveType } from "src/stores/types";
import useChannelsStore from "src/stores/Channels";
import firebase from "firebase";
import MultipleAnalytics from "src/reusable/MultipleAnalytics";
import ProfileInteractions from "./ProfileInteractions";
import ProfileInteractionsCharts from "./ProfileInteractionsCharts";
import axios from "axios";
import useVodInteractiveStore from "src/stores/VodInteractive";

let userProfileSubscriber = () => {};

const VodInteractivityAnalytics = ({ match }) => {
  const timestamp = firebase.firestore.FieldValue.serverTimestamp();

  const {
    //   interactives,
    //   profileInteractions,
    interactiveStatus,
    interactiveError,
    getChannelInteractives,
    //   updateInteractivity,
    deleteInteractivity,
    resetProfileInteractions,
  } = useInteractiveStore();

  const {
    interactives,
    profileInteractions,
    //   interactiveStatus,
    //   interactiveError,
    getResourceInteractives,
    //   updateInteractivity,
    //   deleteInteractivity,
    getUserProfileInteractions,
    //   resetProfileInteractions,
  } = useVodInteractiveStore();

  const { getChannels, channelsData } = useChannelsStore();
  const history = useHistory();
  const [darkMode, setDarkMode] = useState(true);
  //const usersData = useSelector(state => state.usersData)
  const [modalAlert, setModalAlert] = useState(null);
  // const [interactives, setInteractives] = useState([]);
  const [selectedInteractivity, setSelectedInteractivity] = useState(null);
  const [liveViewers, setLiveViewers] = useState(0);
  const [currentChannel, setCurrentChannel] = useState(
    channelsData[match.params.resourceId]
  );
  const [
    selectedInteractivityInteractions,
    setSelectedInteractivityInteractions,
  ] = useState(null);

  useEffect(() => {
    const channelInteractives = interactives?.[match?.params?.resourceId] || [];
    if (channelInteractives.some((x) => x.status === "active")) {
      setSelectedInteractivity(
        channelInteractives.find((x) => x.status === "active")
      );
    } else {
      setSelectedInteractivity(null);
      userProfileSubscriber();
    }
  }, [interactives]);

  const getInteractives = (resourceId, episodeId) => {
    if (resourceId && episodeId) {
      console.log(`episode id av`, episodeId);

      getResourceInteractives({
        resourceId: resourceId,
        episodeId: episodeId,
      });
    } else if (resourceId) {
      console.log(`episode not available`);
      getResourceInteractives({
        resourceId: resourceId
      });
    }
  };

  useEffect(() => {
    getChannels();
  }, []);

  useEffect(() => {
    // getInteractives(match.params.resourceId, match.params.episodeId);
    console.log(`match.params.episodeId`,match.params.episodeId);
    
  }, [match.params.resourceId, match.params.episodeId]);

  useEffect(() => {
    setCurrentChannel(channelsData[match.params.resourceId]);
  }, [channelsData]);

  // const getInteractives = () => {
  //   if (match.params.resourceId) {
  //     getResourceInteractives({ resourceId: match.params.resourceId });
  //   }
  // };

  // const updateInteractive = (id, updates) => {
  //   updateInteractivity({
  //     resourceId: match.params.id,
  //     interactiveId: id,
  //     data: updates,
  //   });
  // };

  useEffect(() => {
    if (selectedInteractivity) {
      getUserProfileInteractions({
        setUnsubscribe: (func) => (userProfileSubscriber = func),
        resourceId: selectedInteractivity?.resourceId,
        interactiveId: selectedInteractivity?.interactiveId,
      });
    } else {
      setSelectedInteractivity(null);
      userProfileSubscriber();
    }
  }, [selectedInteractivity]);

  useEffect(() => {
    if (profileInteractions?.[selectedInteractivity?.interactiveId]) {
      const channelInteractions =
        profileInteractions?.[selectedInteractivity?.interactiveId];

      // console.log(
      //   `profileInteractions?.[selectedInteractivity?.interactiveId]`,
      //   profileInteractions?.[selectedInteractivity?.interactiveId]
      // );

      let opts = {};
      let total = 0;

      if (selectedInteractivity?.type === "quiz") {
        // For QUIZ
        const questionInteractions = {};
        selectedInteractivity?.questions?.forEach((qItem) => {
          let qOpts = {};
          let qTotal = 0;
          qItem?.choices?.forEach((item) => {
            const newCounter =
              channelInteractions?.filter(
                (obj) =>
                  obj[`question-${qItem?.questionId}`]?.selected === item?.optId
              )?.length || 0;
            qOpts[`${item.optId}`] = newCounter;
            qTotal += newCounter;
          });
          qOpts["total"] = qTotal;
          questionInteractions[`${qItem.questionId}`] = qOpts;
        });
        setSelectedInteractivityInteractions(questionInteractions);
      } else if (selectedInteractivity?.type === "ads") {
        // For ADS
        selectedInteractivity?.options?.forEach((item) => {
          const interactionKey = `click-${item.optId}`;
          const newCounter = channelInteractions.reduce((count, obj) => {
            const clicks = obj[interactionKey];
            return count + (Array.isArray(clicks) ? clicks.length : 0);
          }, 0);
          opts[`${item.optId}`] = newCounter;
          total += newCounter;
        });
        opts["total"] = total;
        setSelectedInteractivityInteractions(opts);
      } else {
        // For POLLS
        selectedInteractivity?.options?.forEach((item) => {
          const newCounter =
            channelInteractions?.filter((obj) => obj.selected === item.optId)
              ?.length || 0;
          opts[`${item.optId}`] = newCounter;
          total += newCounter;
        });
        opts["total"] = total;
        setSelectedInteractivityInteractions(opts);
      }
    }
  }, [profileInteractions, selectedInteractivity]);

  function getViewsLink(streamUrl) {
    // Extract the base URL up to the video ID
    const baseUrl = streamUrl.split("/manifest")[0];

    // Construct the new URL for the views
    const viewsUrl = `${baseUrl}/views`;

    return viewsUrl;
  }

  // Function to fetch the data from the views URL
  const fetchViewsData = async (streamUrl) => {
    try {
      const viewsUrl = getViewsLink(streamUrl);
      const response = await axios.get(viewsUrl);
      // console.log("Views Data:", response.data);
      setLiveViewers(response.data.liveViewers);
    } catch (error) {
      console.error("Error fetching views data:", error);
    }
  };

  useEffect(() => {
    fetchViewsData(currentChannel?.url);

    // console.log(`liveViewers`, liveViewers);
  }, [currentChannel]);

  // useEffect(() => {
  // setTimeout(() => {
  //   fetchViewsData(currentChannel?.url);
  // }, 10000);
  //   console.log(`liveViewers`, liveViewers);
  // }, [fetchViewsData,currentChannel, liveViewers]);

  return (
    <div className={`c-app ${darkMode ? "c-dark-theme" : ""}`}>
      <CContainer fluid>
        <CButton
          className="btn-danger mt-3 pt-3 d-flex justify-content-center align-items-center"
          onClick={() => history.goBack()}
        >
          <h6>{"< Back to Interactivity Manager"}</h6>
        </CButton>
        <CRow className="flex-fill">
          <CCol md="8" className="inter-col d-flex flex-column">
            <h1 className="font-weight-bold">
              {currentChannel?.title.en
                ? currentChannel?.title.en
                : currentChannel?.title}{" "}
              {` -Live Viewers= ${liveViewers}`}
            </h1>
            <h5 className="inter-title">INTERACTIVITY ANALYTICS</h5>
            <div className="">
              {selectedInteractivity ? (
                <>
                  <CCard borderColor="primary">
                    <CCardHeader>
                      <h5 className="font-weight-bold inter-type">
                        {selectedInteractivity?.type}
                      </h5>
                    </CCardHeader>
                    <CCardBody
                      className="inter-col-content"
                      style={{ height: "500px" }}
                    >
                      <h1>{selectedInteractivity?.title}</h1>
                      <h4 className="mt-4">
                        {selectedInteractivity?.type === "quiz"
                          ? "Questions"
                          : "Options"}
                        :
                      </h4>
                      {selectedInteractivity?.type !== "quiz"
                        ? selectedInteractivity?.options?.map((item, index) => {
                            return (
                              <CCard
                                key={`c-${index}`}
                                color="secondary"
                                className="mb-2"
                              >
                                <CCardBody className="p-3 d-flex align-items-center justify-content-between ">
                                  <div className="d-flex align-items-center ">
                                    {item.imageUrl ? (
                                      <img
                                        src={item.imageUrl}
                                        className="inter-opt-img"
                                      />
                                    ) : null}
                                    {item.iconUrl ? (
                                      <img
                                        src={item.iconUrl}
                                        className="inter-opt-img"
                                      />
                                    ) : null}
                                    <h5 className="mb-0">
                                      {item.text} -(OptId= <b> {item.optId}</b>{" "}
                                      )
                                    </h5>
                                  </div>
                                  <div className="d-flex align-items-center ">
                                    <CBadge className="mr-2">
                                      <h4 className="mb-0">
                                        {selectedInteractivityInteractions?.[
                                          `${item.optId}`
                                        ] || 0}
                                      </h4>
                                    </CBadge>
                                    <CBadge color="info">
                                      <h4 className="mb-0">
                                        {(
                                          ((selectedInteractivityInteractions?.[
                                            `${item.optId}`
                                          ] || 0) /
                                            selectedInteractivityInteractions?.[
                                              "total"
                                            ]) *
                                            100 || 0
                                        ).toFixed() || 0}
                                        %
                                      </h4>
                                    </CBadge>
                                  </div>
                                </CCardBody>
                              </CCard>
                            );
                          })
                        : null}
                      {selectedInteractivity?.type === "quiz"
                        ? selectedInteractivity?.questions?.map(
                            (qItem, qIndex) => {
                              return (
                                <CCard
                                  key={`k-${qIndex}`}
                                  borderColor={
                                    selectedInteractivity?.currentQuestion ===
                                    qIndex
                                      ? "primary"
                                      : "light"
                                  }
                                >
                                  <CCardBody
                                    className="p-2"
                                    style={
                                      selectedInteractivity?.currentQuestion ===
                                      qIndex
                                        ? {
                                            backgroundColor:
                                              "rgba(70,56,194,0.3)",
                                          }
                                        : {}
                                    }
                                  >
                                    <h4>
                                      <CButton
                                        color="warning"
                                        className="mr-2"
                                        //   onClick={() => {
                                        //     updateInteractive(
                                        //       selectedInteractivity?.interactiveId,
                                        //       {
                                        //         currentQuestion: qIndex,
                                        //       }
                                        //     );
                                        //   }}
                                      >
                                        {qIndex + 1}
                                      </CButton>
                                      {qItem.question} (questionId=
                                      {qItem.questionId})
                                    </h4>
                                    {qItem?.choices?.map((cItem, cIndex) => {
                                      return (
                                        <CCard
                                          key={`c-${cIndex}`}
                                          color="secondary"
                                          className="mb-2"
                                        >
                                          <CCardBody
                                            className="p-2 d-flex align-items-center justify-content-between "
                                            style={
                                              qItem.choicesAnswer === cIndex
                                                ? {
                                                    backgroundColor:
                                                      "rgba(0,255,0,0.2)",
                                                  }
                                                : {}
                                            }
                                          >
                                            <div className="d-flex align-items-center ">
                                              {cItem.imageUrl ? (
                                                <h4>{cItem.imageUrl}</h4>
                                              ) : // <img src={item.imageUrl} className="inter-opt-img" />
                                              null}
                                              {cItem.iconUrl ? (
                                                <img
                                                  src={cItem.iconUrl}
                                                  className="inter-opt-img"
                                                />
                                              ) : null}
                                              <h5 className="mb-0">
                                                {cItem.text} (choiceId={" "}
                                                {cItem.optId})
                                              </h5>
                                            </div>
                                            <div className="d-flex align-items-center ">
                                              <CBadge className="mr-2">
                                                <h4 className="mb-0">
                                                  {selectedInteractivityInteractions?.[
                                                    `${qItem.questionId}`
                                                  ]?.[`${cItem.optId}`] || 0}
                                                </h4>
                                              </CBadge>
                                              <CBadge color="info">
                                                <h4 className="mb-0">
                                                  {(
                                                    ((selectedInteractivityInteractions?.[
                                                      `${qItem.questionId}`
                                                    ]?.[`${cItem.optId}`] ||
                                                      0) /
                                                      selectedInteractivityInteractions?.[
                                                        `${qItem.questionId}`
                                                      ]?.[`total`]) *
                                                      100 || 0
                                                  ).toFixed() || 0}
                                                  %
                                                </h4>
                                              </CBadge>
                                            </div>
                                          </CCardBody>
                                        </CCard>
                                      );
                                    })}
                                  </CCardBody>
                                </CCard>
                              );
                            }
                          )
                        : null}
                    </CCardBody>
                    <CCardFooter className="d-flex justify-content-between">
                      {/* <div className="d-flex align-items-center">
                          {selectedInteractivity?.type === "quiz" ? (
                            <>
                              <CButton
                                color="primary"
                                className="mr-2"
                                //   onClick={() => {
                                //     updateInteractive(selectedInteractivity?.interactiveId, {
                                //       currentQuestion:
                                //         selectedInteractivity?.currentQuestion - 1,
                                //     });
                                //   }}
                                disabled={!selectedInteractivity?.currentQuestion}
                              >
                                PREV
                              </CButton>
                              <span>Q# </span>
                              <CSelect
                                color="danger"
                                custom
                                name="currentQues"
                                id="currentQues"
                                className="ml-2 mr-2"
                                style={{ width: 60 }}
                                //   onChange={(e) => {
                                //     updateInteractive(selectedInteractivity?.interactiveId, {
                                //       currentQuestion: Number(e.target.value),
                                //     });
                                //   }}
                                value={selectedInteractivity?.currentQuestion}
                              >
                                {[
                                  ...new Array(
                                    selectedInteractivity?.questions?.length
                                  ),
                                ].map((item, index) => {
                                  return (
                                    <option key={`choice-${index}`} value={index}>
                                      {index + 1}
                                    </option>
                                  );
                                })}
                              </CSelect>
                              <CButton
                                color="primary"
                                className="mr-2"
                                //   onClick={() => {
                                //     updateInteractive(selectedInteractivity?.interactiveId, {
                                //       currentQuestion:
                                //         selectedInteractivity?.currentQuestion + 1,
                                //     });
                                //   }}
                                disabled={
                                  selectedInteractivity?.currentQuestion >=
                                  selectedInteractivity?.questions?.length - 1
                                }
                              >
                                NEXT
                              </CButton>
                            </>
                          ) : null}
                        </div> */}
                      {/* <div className="d-flex align-items-center">
                          {selectedInteractivity?.type === "quiz" ? (
                            <CButton
                              className="d-flex align-items-center p-2 mr-2"
                              color={
                                selectedInteractivity?.showQuiz
                                  ? "success"
                                  : "dark"
                              }
                              onClick={() => {
                                const updateData = {
                                  showQuiz: !selectedInteractivity?.showQuiz,
                                };
                                if (selectedInteractivity?.showQuiz === false) {
                                  updateData.activatedAt = timestamp;
                                } else {
                                  updateData.deactivatedAt = timestamp;
                                }
                                //   updateInteractive(
                                //     selectedInteractivity?.interactiveId,
                                //     updateData
                                //   );
                              }}
                            >
                              <h6 className="mb-0 mr-2">SHOW QUIZ</h6>
                              <CSwitch
                                className=""
                                color="success"
                                checked={selectedInteractivity?.showQuiz}
                                onChange={() => {}}
                              />
                            </CButton>
                          ) : null}
                          <CButton
                            size="md"
                            color="danger"
                            className={"mr-2"}
                            onClick={() => {
                              resetProfileInteractions({
                                resourceId: selectedInteractivity?.resourceId,
                                interactiveId:
                                  selectedInteractivity?.interactiveId,
                              });
                            }}
                          >
                            <h5 className="mb-0">Reset</h5>
                          </CButton>
                          <CButton
                            size="md"
                            color="danger"
                            //   onClick={() =>
                            //     updateInteractive(selectedInteractivity.interactiveId, {
                            //       status: "inactive",
                            //     })
                            //   }
                          >
                            <h5 className="mb-0">STOP INTERACTIVITY</h5>
                          </CButton>
                        </div> */}
                    </CCardFooter>
                  </CCard>
                  {/* <MultipleAnalytics title={"Hello"} chartType={"bar"} datasets={selectedInteractivity} labels={"audience"}  /> */}

                  <div>
                    <h3>Live Viewers:{liveViewers}</h3>
                  </div>

                  <ProfileInteractions
                    profileInteractions={profileInteractions}
                    selectedInteractivity={selectedInteractivity}
                  />
                  {/* <ProfileInteractionsCharts
                      profileInteractions={
                        profileInteractions?.[
                          selectedInteractivity?.interactiveId
                        ]
                      }
                      // selectedInteractivity={selectedInteractivity}
                    /> */}
                </>
              ) : (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: "100%" }}
                >
                  SELECT AN INTERACTIVITY TO VIEW DATAS
                </div>
              )}
            </div>
            <div className="d-flex justify-content-center align-items-center inter-controller"></div>
          </CCol>
          <CCol md="4" className="inter-col">
            <div className="d-flex justify-content-between mb-4">
              <div>
                <label className="mr-2">Sort by: </label>
                <CSelect
                  color="danger"
                  className="inter-filter"
                  custom
                  name="filter"
                  id="filter"
                  // value={this.state.difficultyCode}
                  onChange={(e) => {}}
                >
                  {[{ name: "By Date", value: "date" }].map((item, index) => {
                    return (
                      <option key={`date-${index}`} value={`${item.value}`}>
                        {item.name}
                      </option>
                    );
                  })}
                </CSelect>
              </div>
            </div>
            <div className="inter-col-content">
              {interactives?.[match.params.resourceId]?.map((item, index) => {
                return (
                  <CCard
                    key={`e-${index}`}
                    color={selectedInteractivity === item ? "primary" : ""}
                    borderColor="light"
                    onClick={() => {
                      setSelectedInteractivity(item);
                    }}
                  >
                    <CCardHeader className="d-flex justify-content-between p-2">
                      <div>
                        <h5 className="mb-0 inter-type">{item.type}</h5>
                      </div>
                    </CCardHeader>
                    <CCardBody className="p-2 d-flex justify-content-between align-items-end">
                      <div>
                        <h3 className="mb-1">{item.title}</h3>
                        <CBadge color={"success"} className="mr-2">
                          {item.type === "quiz"
                            ? `${item.questions?.length} questions`
                            : `${item.options?.length} options`}
                        </CBadge>
                        <br />
                        <CBadge color={"light"} className="mr-2">
                          Created:{" "}
                          {item?.createdAt
                            ? moment(item.createdAt.toDate()).format("lll")
                            : ""}
                        </CBadge>
                        <CBadge color={"light"} className="mr-2">
                          Updated:{" "}
                          {item?.updatedAt
                            ? moment(item.updatedAt.toDate()).format("lll")
                            : ""}
                        </CBadge>
                      </div>
                      <div></div>
                    </CCardBody>
                  </CCard>
                );
              })}
            </div>
            <div className="mt-5">
              <ProfileInteractionsCharts
                profileInteractions={
                  profileInteractions?.[selectedInteractivity?.interactiveId]
                }
                // selectedInteractivity={selectedInteractivity}
              />
            </div>
          </CCol>
        </CRow>
      </CContainer>
      <CModal
        show={modalAlert ? true : false}
        onClose={() => {
          setModalAlert(null);
          history.push("/");
        }}
        color={modalAlert?.color || "secondary"}
      >
        <CModalHeader closeButton>
          <CModalTitle>{modalAlert?.title || ""}</CModalTitle>
        </CModalHeader>
        <CModalBody>{modalAlert?.message || ""}</CModalBody>
        <CModalFooter>
          {modalAlert?.buttons?.map((item, index) => {
            return (
              <CButton
                key={`m-${index}`}
                color={item.color || "secondary"}
                onClick={item.onClick}
              >
                {item.title}
              </CButton>
            );
          })}
        </CModalFooter>
      </CModal>
    </div>
  );
};

export default VodInteractivityAnalytics;
