import { create } from "zustand";
import firebase from "firebase";
import { resourceType, vodInteractiveType } from "./types";

const timestamp = firebase.firestore.FieldValue.serverTimestamp();
const useVodInteractiveStore = create((set, get) => ({
  interactivities: [],
  interactives: {},
  profileInteractions: {},
  currentInteractive: null,
  interactiveStatus: "",
  interactiveLoading: false,
  interactiveError: "",
  // Get User Profile Interactions
  getUserProfileInteractions: ({
    setUnsubscribe,
    resourceId,
    interactiveId,
    episodeId,
  }) => {
    if (episodeId) {
      // console.log(`episodeId`, episodeId);
      const unsubscribe = firebase
        .firestore()
        .collectionGroup("profileInteractions")
        .where("resourceId", "==", resourceId)
        .where("interactiveId", "==", interactiveId)
        .where("episodeId", "==", episodeId)
        .onSnapshot((snaps) => {
          let results = snaps?.docs.map((doc) => doc.data());
          set({
            profileInteractions: {
              ...get().profileInteractions,
              [`${interactiveId}`]: results,
            },
            interactiveStatus:
              vodInteractiveType.GETPROFILEINTERACTIONS_SUCCESS,
          });
        });
      setUnsubscribe(unsubscribe);
    } else {
      const unsubscribe = firebase
        .firestore()
        .collectionGroup("profileInteractions")
        .where("resourceId", "==", resourceId)
        .where("interactiveId", "==", interactiveId)
        .onSnapshot((snaps) => {
          let results = snaps?.docs.map((doc) => doc.data());
          set({
            profileInteractions: {
              ...get().profileInteractions,
              [`${interactiveId}`]: results,
            },
            interactiveStatus:
              vodInteractiveType.GETPROFILEINTERACTIONS_SUCCESS,
          });
        });
      setUnsubscribe(unsubscribe);
    }
  },
  // Get Resource's Interactivity
  getResourceInteractives: ({ resourceId, episodeId }) => {
    console.log(`getRes resId`, resourceId);
    console.log(`getRes episodeId`, episodeId);
    set({
      interactiveStatus: vodInteractiveType.GETVODINTERACTIVITIES_LOADING,
    });

    // console.log(`getRes resId`, resourceId);
    var resourceRef;
    if (episodeId) {
      // console.log(`episodeId`, episodeId);
      resourceRef = firebase
        .firestore()
        .collection("resources")
        .doc(resourceId)
        .collection(`episodes`)
        .doc(episodeId);
    } else {
      resourceRef = firebase
        .firestore()
        .collection("resources")
        .doc(resourceId);
    }

    resourceRef.get().then((snap) => {
      let results = snap.data();
      console.log(`getResourceInteractives`, results?.interactivity);
      set({
        interactivities: results?.interactivity,
        interactives: {
          ...get().interactives,
          [`${resourceId}`]: results?.interactivity,
        },
        interactiveStatus: vodInteractiveType.GETVODINTERACTIVITIES_SUCCESS,
      });
    });
  },

  // Create Interactivity
  createVodInteractivity: (resourceId, interactiveData, episodeId) => {
    const uid = firebase.auth().currentUser.uid;

    // console.log(`getRes resId`, resourceId);
    var resourceRef;
    if (episodeId) {
      // console.log(`episodeId`, episodeId);
      resourceRef = firebase
        .firestore()
        .collection("resources")
        .doc(resourceId)
        .collection(`episodes`)
        .doc(episodeId);
    } else {
      resourceRef = firebase
        .firestore()
        .collection("resources")
        .doc(resourceId);
    }
    // const resourceRef = firebase
    //   .firestore()
    //   .collection("resources")
    //   .doc(resourceId);

    set({ resourceStatus: resourceType.GETRESOURCE_LOADING });
    resourceRef
      .get()
      .then((snap) => {
        set({
          interactiveStatus: vodInteractiveType.CREATEVODINTERACTIVITY_LOADING,
        });
        const result = snap.data();
        const resourceId = result.resourceId;
        // console.log(`result=`, result.resourceId);

        const existingActions = result.interactivity
          ? result.interactivity
          : [];
        // Generate a unique ID for the new action
        const newActionId = `${existingActions?.length}`;

        // const newAction = {
        //   id: newActionId,
        //   actions: [
        //     { ...interactiveData, authorId: uid, resourceId: resourceId },
        //   ],
        // };
        var newAction;
        episodeId
          ? (newAction = {
              ...interactiveData,
              authorId: uid,
              resourceId: resourceId,
              episodeId: episodeId,
            })
          : (newAction = {
              ...interactiveData,
              authorId: uid,
              resourceId: resourceId,
            });

        // const newAction = {
        //   ...interactiveData,
        //   authorId: uid,
        //   resourceId: resourceId,
        // };

        // Update the existing actions array or create a new one
        const updatedActions = [...existingActions, newAction];

        // console.log(`updatedActions=`, updatedActions);

        try {
          resourceRef.set(
            {
              interactivity: updatedActions,
            },
            { merge: true }
          );
          set({
            interactiveStatus:
              vodInteractiveType.CREATEVODINTERACTIVITY_SUCCESS,
          });
        } catch (error) {
          console.log(`updateAction error`, error);
          set({
            interactiveStatus: vodInteractiveType.CREATEVODINTERACTIVITY_FAIL,
          });
        }
      })
      .catch((error) => {
        set({
          resourceStatus: resourceType.GETRESOURCE_FAIL,
          resourceError: error.message,
        });
      });
  },

  // Update Interactivity
  updateInteractivity: ({ resourceId, interactivityItem, data, episodeId }) => {
    console.log(`interactivityItem`, interactivityItem, data);
    const existingInteractivites = get()?.interactivities;
    // const existingActionData = interactivityItem?.actions[0] || [];
    const existingActionData = interactivityItem || [];

    console.log(`existingInteractivites`, existingInteractivites);
    console.log(`existingActionData`, existingActionData);
    // console.log(`resourceId`,resourceId);

    const updates = { ...existingActionData, ...data };

    set({
      interactiveStatus: vodInteractiveType.UPDATEVODINTERACTIVITY_LOADING,
    });

    // console.log(`getRes resId`, resourceId);
    var resourceRef;
    if (episodeId) {
      // console.log(`episodeId`, episodeId);
      resourceRef = firebase
        .firestore()
        .collection("resources")
        .doc(resourceId)
        .collection(`episodes`)
        .doc(episodeId);
    } else {
      resourceRef = firebase
        .firestore()
        .collection("resources")
        .doc(resourceId);
    }
    resourceRef
      .update({
        interactivity: existingInteractivites.map((item) =>
          item?.id === interactivityItem?.id
            ? // Update specific action within the matching interactivityItem
              // actions: updates,
              updates
            : item
        ),
      })
      .then(() => {
        set({
          interactiveStatus: vodInteractiveType.UPDATEVODINTERACTIVITY_SUCCESS,
        });
        console.log(`update interactivity success`);
      })
      .catch((error) => {
        set({
          interactiveStatus: vodInteractiveType.UPDATEVODINTERACTIVITY_FAIL,
          interactiveError: error?.message,
        });
        console.log(`update interactivity error`, error?.message);
      });
  },

  // Delete Interactivity
  deleteInteractivity: ({ resourceId, interactiveItem, episodeId }) => {
    console.log(`interactiveItem`, interactiveItem);
    const existingInteractivites = get()?.interactivities;
    // const existingActionData = interactivityItem?.actions[0] || [];

    // const updates = [{ ...existingActionData, ...data }];

    set({
      interactiveStatus: vodInteractiveType.DELETEVODINTERACTIVITY_LOADING,
    });
    var resourceRef;
    if (episodeId) {
      // console.log(`episodeId`, episodeId);
      resourceRef = firebase
        .firestore()
        .collection("resources")
        .doc(resourceId)
        .collection(`episodes`)
        .doc(episodeId);
    } else {
      resourceRef = firebase
        .firestore()
        .collection("resources")
        .doc(resourceId);
    }
    resourceRef
      .update({
        interactivity: existingInteractivites.filter(
          (item) => item.id !== interactiveItem?.id
        ),
      })
      .then(() => {
        set({
          interactiveStatus: vodInteractiveType.DELETEVODINTERACTIVITY_SUCCESS,
        });
        console.log(`delete interactivity success`);
      })
      .catch((error) => {
        set({
          interactiveStatus: vodInteractiveType.DELETEVODINTERACTIVITY_FAIL,
          interactiveError: error?.message,
        });
        console.log(`delete interactivity error`, error?.message);
      });
  },

  // Get User Profile Interactions
  // getUserProfileInteractions: ({ setUnsubscribe, resourceId, interactiveId }) => {

  //   const unsubscribe = firebase.firestore().collectionGroup('profileInteractions')
  //   .where('resourceId', '==', resourceId)
  //   .where('interactiveId', '==', interactiveId)
  //   .onSnapshot((snaps) => {
  //     let results = snaps?.docs.map(doc => doc.data());
  //     set({
  //       profileInteractions: {
  //         ...get().profileInteractions,
  //         [`${interactiveId}`]: results,
  //       },
  //       interactiveStatus: vodInteractiveType.GETPROFILEINTERACTIONS_SUCCESS,
  //     });
  //   });
  //   setUnsubscribe(unsubscribe);
  // },

  // resetProfileInteractions: ({ resourceId, interactiveId }) => {
  //   console.log('starting reset')
  //   firebase.firestore().collectionGroup('profileInteractions')
  //   .where('resourceId', '==', resourceId)
  //   .where('interactiveId', '==', interactiveId)
  //   .get()
  //   .then((snaps) => {
  //     let results = snaps?.docs.map(doc => doc.data());
  //     console.log(`to delete - ${JSON.stringify(results)}`)
  //     results.forEach((item) => {
  //       firebase.firestore().collection('users').doc(item.uid)
  //       .collection('profiles').doc(item.profileId)
  //       .collection('profileInteractions')
  //       .doc(`${resourceId}-${interactiveId}`)
  //       .delete();
  //     })
  //   })
  // },
}));

export default useVodInteractiveStore;
